/*
=======================================================
					SETTINGS
=======================================================
*/

// -- Layout Variablen


// --- Fonts
@f_mainSize			: 16px;
@f_mainLineHeight	: 160%;

.f_1stFont {
	font-family: 'Calibri Regular', sans-serif;
}

.f_1stFontItalic {
	.f_1stFont;
	font-style: italic;
}

.f_1stFontBook {
	.f_1stFont;
	font-weight: 400;
}

.f_1stFontBookItalic {
	.f_1stFontBook;
	font-style: italic;
}

.f_1stFontLight {
	font-family: 'Calibri Light', sans-serif;
}

.f_1stFontLightItalic {
	.f_1stFontLight;
	font-style: italic;
}

.f_1stFontSemiBold {
	.f_1stFont;
	font-weight: 600;
}

.f_1stFontSemiBoldItalic {
	.f_1stFontSemiBold;
	font-style: italic;
}

.f_1stFontBold {
	font-family: 'Calibri Bold';
	//font-weight:700;
}

.f_1stFontBoldItalic {
	.f_1stFontBold;
	font-style: italic;
}

.f_1stFontXBold {
	.f_1stFont;
	font-weight: 800;
}

.f_1stFontXBoldItalic {
	.f_1stFontXBold;
	font-style: italic;
}

.f_1stFontBlack {
	.f_1stFont;
	font-weight: 800;
}

.f_1stFontBlackItalic {
	.f_1stFontBlack;
	font-style: italic;
}

.f_2ndFont{
	font-family: 'Roboto Regular', sans-serif;
}

.f_2ndFontBold{
	font-family: 'Roboto Bold',sans-serif;
	font-weight: 700;
}

.f_2ndFontThin{
	font-family: 'Roboto Thin',sans-serif;
	font-weight:100;
}

.f_2ndFontLight{
	font-family: 'Roboto Light',sans-serif;
}

	// -- Colors
	@c_1st		: #3c3d32;		// Textfarbe schwarz
	@c_2nd		: #073100;		//
	@c_3rd		: #16470e;		//
	@c_4th		: #939393;		//
	@c_5th		: @c_3rd;			//
	@c_6th		: #fff;	        //
	@c_7th 		: #d2dad0;		// HKS Hell Grün
	@c_8th		: #000000;		//
	@c_9th      : #c8c8c8;       //
	@c_10th     : transparent ;  //

	@c_petrolRed		: #d20019;
	@c_lpgOrange		: #ef7c00;
	@c_dieselYellow		: #ffcc00;
	@c_nanoGreen		: #78ac28;
	@c_airconBlue		: #009ee3;
	@c_carCareMint		: #13b0ac;
	@c_automatikGrey	: #626261;
	@c_oilGreen			: #4f812d;
	@c_coolerBlue		: #0068b4;


	// --- Widths & Heights
	@w_page				: 100%;                   // Breite des Seiten Wrappers
	@w_wrapper			: 100%;		              // Breite des Inneren Content Wrappers

	@s_width_fullscreen :100%;
	@s_height_fullscreen:100%;
	@s_width_big        :300px;                     // Logo Höhe Groß
	@s_height_big       :300px;                     // Logo Breite Groß
	@s_width_small      :100px;                     // Logo Höhe Klein
	@s_height_small     :100px;                     // LOgo Breite Klein
	@s_monitor_height	:1080px;					// Monitor Screen Höhe
	@s_monitor_width	:1920px;					// Monitor Screen Breite

	@r_borderRadius     : @m_main / 2;            // Standard Border-Radius

	@w_header			: 100%;		                // Breite des Headers

	@w_slideshow		: @w_header;	            // Breite der Header Slideshow
	@h_slideshow		: 380px;		            // Höhe der Slideshow

	@w_sidebarFirst		: 24%;			            // Breite Sidebar First
	@w_sidebarSecond	: 20%;			            // Breite Sidebar Second

	@w_pageArea			: 100%;			            // Breite der Gesamten Page Area

	// --- Margins
	@m_main			    : 10px;				        // Standard Margin
	@m_block		    : 10px;			            // Margin für Blöcke
	@m_headlines    	: (@m_main / 1.4);	        // Margin für Headlines (Ausgehend von h1)

	// --- Paddings
	@p_main				: 20px;		                // Standard Padding
	@p_block			: @p_main;

	// --- Responsive Angaben
	@r_status           : false;          //Responsive einschalten


	//Textgrößen
	@r_fontSizeText     : 15px;          //Textgröße
	@r_fontSizeH1       : 27px;          //Responsive H1
	@r_fontSizeH2       : 20px;          //Responsive H2
	@r_fontSizeH3       : 17px;          //Responsive H3
	@r_fontSizeH4       : 15px;          //Responsive H4
	@r_fontSizeH5       : 15px;          //Responsive H5
	@r_fontSizeH6       : 15px;          //Responsive H6

// Video Variablen

@track-color: @c_1st;
@thumb-color: @c_5th;

@thumb-radius: 50%;
@thumb-height: 30px;
@thumb-width: 30px;
@thumb-shadow-size: 1px;
@thumb-shadow-blur: 1px;
@thumb-shadow-color: #111;
@thumb-border-width: 1px;
@thumb-border-color: white;

@track-width: 100%;
@track-height: 1px;
@track-shadow-size: 2px;
@track-shadow-blur: 2px;
@track-shadow-color: #222;
@track-border-width: 1px;
@track-border-color: black;

@track-radius: 5px;
@contrast: 5%;

// Media Querys

@q_1st     		: 1367px;
@q_2nd     		: 1281px;
@q_3rd			: 980px;
@q_top_1st		: 38%;
@q_height_1st	: 65%;

@q_scale_1st	: 65;
