@import url("./lib/fonts/fonts.css");
@import url("./lib/icons.less");
@import url("./lib/settings.less");
@import url("./lib/functions.less");
@import url("./lib/animations.less");
@import url("./classes/classes.less");

#content{

	display:block;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	margin:0;
	padding:0;
	z-index:10000;
	background-color:@c_6th;

	.wrapper-content{
		display:block;
		width:100%;
		height:100%;
		margin:0;
		padding:0;


		#tpl-wrapper{

			display:block;
			padding: ( @p_main * 2 );
			height:100%;

			.tpl-content-wrapper{
				
				display:block;
				width:100%;
				height:100%;
				margin:0;
				padding:0;

				header.tpl-header{

					width:100%;
					position:absolute;
					right: ( @p_main * 2 );

					ul#tpl-header-ul{

						margin:0;
						padding:0;
						padding-bottom: ( @p_main * 1.5 ) ;
						list-style:none;
						z-index:100;
	
						.displayFlex(column);
						.alignContent(flex-end);
						.alignItems(flex-end);
						.flexWrap(nowrap);
						.justifyContent(flex-end);

						li{

							display:block;
							margin:0;
							width:80px;
							height:80px;
							border-radius:50%;
							//border:2px solid @c_5th;
							background-color:@c_7th;
							margin-right: (@p_main / 2);
							margin-bottom: (@p_main / 2);
							padding: (@p_main / 2);
							cursor:pointer;
							overflow:hidden;
	
							.standardAnimation(all);
	
							i{
								display:block;
								width:100%;
	
								&:before{
									content:" ";
									margin: 0 auto;
									display:block;
									background-color:@c_5th;
									background-position:center center;
									background-repeat:no-repeat;
								}

								&.icon-close{
									height:35px;

									&:before{

										width:50px;
										height:50px;
										margin-top:15px;
										mask-image: url("/assets/img/svg/icon_close_zeichen.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_close_zeichen.svg");

										@media screen and ( max-width: @q_1st ){
											margin-top:2px;
										}
									}
								}
	
								&.icon-info{
	
									height:35px;
									//margin-bottom:5px;
	
									&:before{
	
										width:40px;
										height:40px;
										margin-top:15px;
										mask-image: url("/assets/img/svg/icon_info.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_info.svg");
	
										@media screen and ( max-width: @q_1st ){
											margin-top:5px;
											width:25px;
											height:25px;
										}
									}
									@media screen and ( max-width: @q_1st ){
										height:30px;
									}
								}
								&.icon-msds{
	
									&:before{
	
										mask-image: url("/assets/img/svg/icon_msds.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_msds.svg");
										width:36px;
										height:44px;
										margin-top:10px;
	
										@media screen and ( max-width: @q_1st ){
											margin-top:5px;
											width:25px;
											height:31px;
										}
									}
									@media screen and ( max-width: @q_1st ){
										margin-top:5px;
										height:36px;
									}
								}
								&.icon-pro-video{
	
									&:before{
										mask-image: url("/assets/img/svg/icon_product_video.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_product_video.svg");
										width:55px;
										height:40px;
										margin-top:13px;
	
										@media screen and ( max-width: @q_1st ){
											margin-top:5px;
											width:40px;
											height:29px;
										}
									}
	
									@media screen and ( max-width: @q_1st ){
										margin-top:0;
										height:35px;
									}
								}
								&.icon-app-video{
	
									&:before{
										mask-image: url("/assets/img/svg/icon_app_video.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_app_video.svg");
										width:55px;
										height:40px;
										margin-top:13px;
	
										@media screen and ( max-width: @q_1st ){
											margin-top:0;
											width:40px;
											height:29px;
										}
									}
									@media screen and ( max-width: @q_1st ){
										margin-top:5px;
										height:35px;
									}
								}
								&.icon-labor-video{
	
									&:before{
										mask-image: url("/assets/img/svg/icon_labor_video.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_labor_video.svg");
										width:55px;
										height:40px;
										margin-top:13px;
	
										@media screen and ( max-width: @q_1st ){
											margin-top:5px;
											width:40px;
											height:29px;
										}
									}
									@media screen and ( max-width: @q_1st ){
										margin-top:0;
										height:35px;
									}
								}
								&.icon-references{
	
									&:before{
										width:38px;
										height:58px;
										margin-top:5px;
										margin-bottom:-7px;
										mask-image: url("/assets/img/svg/icon_references.svg");
										-webkit-mask-image: url("/assets/img/svg/icon_references.svg");
	
										@media screen and ( max-width: @q_1st ){
											margin-top:0;
											width:26px;
											height:39px;
										}
									}
									@media screen and ( max-width: @q_1st ){
									   height:39px;
									}
								}
							}
	
							h4{
								font-size: ( @f_mainSize * 0.5 );
								color:@c_5th;
								text-align:center;
								margin:0;
								padding:0;
								padding-top:(@p_main / 2.5 );
								.f_2ndFontBold;
	
								@media screen and ( max-width: @q_1st ){
									padding-top:0;
									font-size: ( @f_mainSize * 0.45 );
								}
							}
							&.aktiv{
								background-color: @c_5th;
								i{
									&:before{
										background-color:@c_6th;
									}
								}
								h4{
									color:@c_6th;
								}
							}
							@media screen and ( max-width: @q_1st ){
								width:55px;
								height:55px;
							}
						}
						clear:both;
					}
				}

				#tpl-img{

					display:block;
					height:100%;
					float:left;
					margin-left: -( @p_main * 2 );

					#tpl-img-load{

						display:block;
						width:auto;
						height:100%;

						.tpl-img-box{

							display:block;
							height:100%;

							img{
								display:block;
								width:auto;
								height:100%;
							}
						}
					}
					clear:both;
				}

				#tpl-content{
					overflow-y:auto;
					display:block;
					width:100%;
					height:100%;

					.no-scrollbar;

					#tpl-content-load{

						.block-headline{
							h1{
								margin:0 0 @p_main 0;

							}
							h2{
								.h4;
								margin: @p_main 0 ;
							}
						}

						#wrapper-tpl-content-load{

							padding-bottom: ( @p_main * 2 );

							.wrapper-zielgruppe{
								ul{	
				
									list-style:none;
									display:inline-block;
									height:30px;
									width:auto;
									margin:0;
									padding:0;
				
									li{
				
										display:block;
										width:30px;
										height:30px;
										margin:0 ( @p_main / 2 ) 0 0;
										padding:0;
										float:left;
				
				
										img{
											height:100%;
											width:auto;
				
										}
									}
								}
								clear:both;
							}

							.wrapper-product-meta-info{
								h4{
									.h5;
									display:inline-block;
									text-transform:none;
									margin: (@p_main / 2) 0 @p_main 0 ;
								}
							}

							.wrapper-description, .wrapper-benefits{
								h5{
									.h5;
									font-size: ( @f_mainSize * 0.85 );
									margin-bottom:0;
								}
								p{
									margin-top:( @p_main / 2 );
								}
							}

							.wrapper-benefits{
								margin-bottom: ( @p_main * 4 );
							}

							.wrapper-gebinde{

								display:inline-block;
								height:auto;
								width:100%;
	
								ul{
	
									list-style:none;
									margin:0;
									padding:0;
	
									li{
	
										display:inline-block;
										margin-right: @p_main;
	
										p{
											text-align:center;
										}
										
										i{
											width:auto;
											display:block;
											margin: 0 auto;
											background-color:@c_4th;
	
											.standardAnimation(all);
	
											&.active{
												background-color:@c_5th;
											}
										}
	
										&.kanister_pe_60{
											img{height:250px;}
											i{
												height:250px;
												width:264px;
												mask-image: url("/assets/img/svg/kanister_pe_60_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_pe_60_Liter.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 250px / 100 ) * @q_scale_1st;
													width: ( 264px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.kanister_pe_30{
											img{height:242px;}
											i{
												height:242px;
												width:264px;
												mask-image: url("/assets/img/svg/kanister_pe_30_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_pe_30_Liter.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 242px / 100 ) * @q_scale_1st;
													width: ( 264px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.kanister_pe_10{
											img{height:222px;}
											i{
												height:222px;
												width:264px;
												mask-image: url("/assets/img/svg/kanister_pe_10_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_pe_10_Liter.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 222px / 100 ) * @q_scale_1st;
													width: ( 264px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.kanister_pe_5{
											img{height:160px;}
											i{
												height:160px;
												width:133px;
												mask-image: url("/assets/img/svg/kanister_pe_5_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_pe_5_Liter.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 160px / 100 ) * @q_scale_1st;
													width: ( 133px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.kanister_alu_20{
											img{height:380px;}
											i{
												height:380px;
												width:150px;
												mask-image: url("/assets/img/svg/kanister_alu_20_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_alu_20_Liter.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 380px / 100 ) * @q_scale_1st;
													width: ( 150px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.kanister_alu_10{
											img{height:224px;}
											i{
												height:224px;
												width:150px;
												mask-image: url("/assets/img/svg/kanister_alu_10_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_alu_10_Liter.svg");
		
												@media screen and ( max-width: @q_1st ){
													height: ( 224px / 100 ) * @q_scale_1st;
													width: ( 150px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.kanister_alu_5{
											img{
												height:197px;
											}
											i{
												height:197px;
												width:98px;
												mask-image: url("/assets/img/svg/kanister_alu_5_Liter.svg");
												-webkit-mask-image: url("/assets/img/svg/kanister_alu_5_Liter.svg");
		
												@media screen and ( max-width: @q_1st ){
													height: ( 197px / 100 ) * @q_scale_1st;
													width: ( 98px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.flasche_pe_250_dosierer{
											img{height:111px;}
											i{
												height:111px;
												width:29px;
												mask-image: url("/assets/img/svg/flasche_pe_250_ml_dosierer.svg");
												-webkit-mask-image: url("/assets/img/svg/flasche_pe_250_ml_dosierer.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 111px / 100 ) * @q_scale_1st;
													width: ( 29px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.flasche_pe_250{
											img{height:97px;}
											i{
												height:111px;
												width:34px;
												mask-image: url("/assets/img/svg/flasche_pe_250_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/flasche_pe_250_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 111px / 100 ) * @q_scale_1st;
													width: ( 34px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.flasche_pe_300_breit{
											img{height:97px;}
											i{
												height:86px;
												width:50px;
												mask-image: url("/assets/img/svg/flasche_pe_300_ml_breit.svg");
												-webkit-mask-image: url("/assets/img/svg/flasche_pe_300_ml_breit.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 86px / 100 ) * @q_scale_1st;
													width: ( 50px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.flasche_pe_500{
											img{height:130px;}
											i{
												height:130px;
												width:39px;
												mask-image: url("/assets/img/svg/flasche_pe_500_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/flasche_pe_500_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 130px / 100 ) * @q_scale_1st;
													width: ( 39px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.flasche_pe_1000{
											img{height:155px;}
											i{
												height:155px;
												width:60px;
												mask-image: url("/assets/img/svg/flasche_pe_1000_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/flasche_pe_1000_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 155px / 100 ) * @q_scale_1st;
													width: ( 60px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_alu_1000{
											img{height:140px;}
											i{
												height:140px;
												width:50px;
												mask-image: url("/assets/img/svg/dose_alu_1000_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_alu_1000_ml.svg");
		
												@media screen and ( max-width: @q_1st ){
													height: ( 140px / 100 ) * @q_scale_1st;
													width: ( 50px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_alu_300{
											img{height:85px;}
											i{
												height:85px;
												width:27px;
												mask-image: url("/assets/img/svg/dose_alu_300_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_alu_300_ml.svg");
		
												@media screen and ( max-width: @q_1st ){
													height: ( 85px / 100 ) * @q_scale_1st;
													width: ( 27px / 100 ) * @q_scale_1st;
												}
		
											}
										}
										&.dose_alu_150{
											img{height:86px;}
											i{
												height:86px;
												width:35px;
												mask-image: url("/assets/img/svg/dose_alu_150_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_alu_150_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 96px / 100 ) * @q_scale_1st;
													width: ( 35px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_aero_120{
											img{height:126px;}
											i{
												height:126px;
												width:41px;
												mask-image: url("/assets/img/svg/dose_aero_120_ml.svg");
												-webkit-mask-image: url("/assets/data/img/svg/dose_aero_120_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 126px / 100 ) * @q_scale_1st;
													width: ( 41px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_aero_150{
											img{height:90.5px;}
											i{
												height:90.5px;
												width:36px;
												mask-image: url("/assets/img/svg/dose_aero_150_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_aero_150_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 90.5px / 100 ) * @q_scale_1st;
													width: ( 36px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_aero_200_mupfel{
											img{height:78px;}
											i{
												height:78px;
												width:54px;
												mask-image: url("/assets/img/svg/dose_aero_200_ml_mupfel.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_aero_200_ml_mupfel.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 78px / 100 ) * @q_scale_1st;
													width: ( 454px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_aero_400{
											img{height:127px;}
											i{
												height:127px;
												width:48px;
												mask-image: url("/assets/img/svg/dose_aero_400_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_aero_400_ml.svg");
		
												@media screen and ( max-width: @q_1st ){
													height: ( 127px / 100 ) * @q_scale_1st;
													width: ( 48px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.dose_aero_500{
											img{height:149px;}
											i{
												height:149px;
												width:48px;
												mask-image: url("/assets/img/svg/dose_aero_500_ml.svg");
												-webkit-mask-image: url("/assets/img/svg/dose_aero_500_ml.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 149px / 100 ) * @q_scale_1st;
													width: ( 48px / 100 ) * @q_scale_1st;
												}
											}
										}
										&.gat_stream{
											img{height:149px;}
											i{
												height:149px;
												width:149px;
												mask-image: url("/assets/img/svg/gat_stream.svg");
												-webkit-mask-image: url("/assets/img/svg/gat_stream.svg");
												@media screen and ( max-width: @q_1st ){
													height: ( 149px / 100 ) * @q_scale_1st;
													width: ( 149px / 100 ) * @q_scale_1st;
												}
											}
										}
									}
									clear:both;
								}
							}
						}
					}
				}
			}
			clear:both;
		}
	}

	&.inactive{
		right:-100%;
		width:0;
	}
	&.active{
		right:0;
		width:100%;
	}

	.standardAnimation(all, 550ms );

	span.close-btn{
		display:block;
		width:50px;
		height:50px;
		background-color:#f90000;
		position:absolute;
		top:0;
		right: ( @p_main * 3 );
	}

}

#content2{

	display:block;
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	margin:0;
	padding:0;
	z-index:10000;
	background-color:@c_6th;


	&.inactive{
		right:-100%;
		width:0;
	}
	&.active{
		right:0;
		width:100%;
	}

	.standardAnimation(all);

	span.close-btn{

        display:block;
        width:70px!important;
        height:70px!important;
        margin:0;
        padding:0;
        padding-bottom:5px;
        position:absolute;
        top:0;
        right: ( @p_main * 2 );
        background-color:@c_6th!important;
        z-index:10;
        border-radius: 0 0 50% 50%;

        &:before{
            content:" ";
            display:block;
            width:55px;
            height:55px;
            background-image: url("/assets/img/svg/icon_close_zeichen.svg");
            margin: 15px 7.5px 7.5px 7.5px ;
            padding:0;

		}
	}

	.wrapper-content{

		padding: ( @p_main * 2 );
		width:auto;
		height:100%;

		.block-headline{
			h1{
				margin: ( @p_main / 2 ) 0 ( @p_main ) 0;
			}
		}

		#block-referenzen{

			height:100%;
			width:100%;
			display:block;
			overflow:hidden;
			overflow-y:scroll;
			margin-top:-13px;
		
			.no-scrollbar;
		
			#grid-referenzen{
		
				//margin-top:30px;
		
				ul{
		
					list-style:none;
					//display:inline-block;
					margin:0;
					margin-bottom: (@p_main * 4);
					padding:0;
					width:100%;
					height:100%;
		
					//display:grid;
					//grid-gap:@p_main;
					//grid-template-columns: 33.333333% ;
					//grid-template-rows: auto;
					//grid-template-areas:
					//"item-1 item-2 item-3"
					//"item-4 item-5 item-6"
					//"item-7 item-8 item-9"
					//"item-10 item-11 item-12";
		
					.displayFlex(row);
					.alignItems(flex-start);
					.alignContent(center);
					.flexWrap(wrap);
		
					li{
						display:block;
						height:auto;
						//min-height:400px;
						//background-color:#f90000;
						//color:@c_6th;
						margin:0;
						padding:(@p_main / 2) 0;
		
						transition: all 200ms;
						animation: scale-box 200ms ease-out normal backwards;
		
						.standardAnimation(all);
		
						&.item-1{ grid-area: item-1; animation-delay:0;}
						&.item-2{ grid-area: item-2; animation-delay:50ms;}
						&.item-3{ grid-area: item-3; animation-delay:100ms;}
						&.item-4{ grid-area: item-4; animation-delay:150ms;}
						&.item-5{ grid-area: item-5; animation-delay:50ms;}
						&.item-6{ grid-area: item-6; animation-delay:100ms;}
						&.item-7{ grid-area: item-7; animation-delay:150ms;}
						&.item-8{ grid-area: item-8; animation-delay:200ms;}
						&.item-9{ grid-area: item-9; animation-delay:100ms;}
						&.item-10{ grid-area: item-10; animation-delay:150ms;}
						&.item-11{ grid-area: item-11; animation-delay:200ms;}
						&.item-12{ grid-area: item-12; animation-delay:250ms;}
						&.item-13{ grid-area: item-13; animation-delay:300ms;}
						&.item-14{ grid-area: item-14; animation-delay:300ms;}
						&.item-15{ grid-area: item-15; animation-delay:300ms;}
						&.item-16{ grid-area: item-16; animation-delay:300ms;}
						&.item-17{ grid-area: item-17; animation-delay:300ms;}
						&.item-18{ grid-area: item-18; animation-delay:300ms;}
						&.item-19{ grid-area: item-19; animation-delay:300ms;}
						&.item-20{ grid-area: item-20; animation-delay:300ms;}
						&.item-21{ grid-area: item-21; animation-delay:300ms;}
						&.item-22{ grid-area: item-22; animation-delay:300ms;}
		
						img{
							width:100%;
							height:auto;
							display:block;
							margin:0;
							padding:0;
							padding-bottom: ( @p_main * 2 );
							//border:2px solid @c_2nd;
		
							.filter(@_property: grayscale, @_value: 100% );
							.standardAnimation(all);
							//.shadow2;
						}
		
						&:hover{
							img{
								.filter(@_property: grayscale, @_value: 0);
							}
						}
		
						&.reference-small{
							width:20%;
						}
						&.reference-big{
							width:100%;
							img{
		
								margin:0 auto ;
								margin-top:40px;
								width:85%;
		
								//.shadow3;
								.filter( @_property: grayscale, @_value:  0);
							}
						}
						&.reference-off{
							display:none;
						}
					}
				}
			}
		}
	}

	.loading-animation{

		.fullscreen;
        position:absolute;
        
        .standardAnimation(all);

		&.loading-close{
            display:none;
        }
        
		&.loading-open{
			display:block;
		}

		.preloader-dots{

			display:inline-block;
			position:absolute;
			z-index:106;
			top:50%;
			left:50%;
			padding: 0 ( @p_main / 2 ) ( @p_main ) ( @p_main / 2 );
			border-radius: ( @p_main / 2 );
			background-color: rgba(0,0,0, 0.1);

			.transform2d(@translateX: -50%, @translateY: -50%);
			.standardAnimation(all);

			p, a{
				width:100%;
				display:block;
				text-align:center;
				font-size:(@f_mainSize * 0.85);
                color:@c_5th;
			}

			.pre-dots{
				display:block;
				width:15px;
				height:15px;
				background-color:transparent;
				border:1px solid @c_5th;
				border-radius:50%;
				float:left;
				margin: 0 ( @m_main / 2);

				.standardAnimation(all);
				.preloader-animation;
			}
		}

		.kontroll-balken-animation{

			display:block;
			position:absolute;
			left:0;
			bottom:0;
			margin:0;
			padding:0;
			height:0;
			width:0;
			z-index:1;
			background-color:transparent;

		}
	}
	#wrapper-tpl-provideo{

		display:block;
		position:absolute;
		top:50px;
		left:50%;
		width:100%;
		height:100%;
		z-index:15;
	
		.animation-scale;
		.transform2d(@translateX: -50%, @translateY: -50%);
	
		&.video-small{
			.block-provideo{
				#vid-controls{
	
					width:100%!important;
					height:auto;
					margin:0;
					padding:0;
					z-index:100;
					position:relative;
					background-color: transparent;
	
					#playBtn{
	
						width:130px;
						height:130px;
						display:inline-block;
						position:absolute;
						opacity:0;
						top:50%;
						left:50%;
						cursor:pointer;
						z-index:15;
	
						.transform2d( @scale: 1);
						.standardAnimation(all);
	
						.circle-playBtn{
	
							border-radius:50%;
							border:15px solid @c_5th;
							display:block;
							width:100px;
							height:100px;
	
							.standardAnimation(all);
	
							&:before{
								content:" ";
								display:block;
								width: 35px;
								height: auto;
								position: absolute;
								-webkit-transform:rotate(360deg);
								border-style: solid;
								border-width: 25px 0 25px 35px;
								border-color: transparent transparent transparent @c_5th;
								top:50%;
								left:50%;
	
								.transform2d(@translateY: -50%, @translateX: -12.5%);
								.standardAnimation(all);
							}
						}
						&:hover{
							.transform2d( @scale: 1.05);
	
						}
					}
					#vid-control-btn{
						display:none;
					}
				}
				.block-load-box{
					display:inline-block;
					position:absolute;
					top:0;
					left:0;
	
					video{
						display:block;
						width:100%;
						height:auto;
						border: 1px solid @c_5th;
					}
					input[type=range]{
						display:none;
					}
				}
			}
		}
		&.video-big{

			.transform2d(@translateX: -50%, @translateY: -50%);

			.block-provideo{
				#vid-controls{
				
					width:100%;
					height:auto;
					margin:0;
					padding:0;
					z-index:5;
					position:relative;
					background-color: transparent;
					
					#wrapper-control-btn-ul{
	
						display:none;
						width:100%;
						height:100%;
						margin:0;
						padding:0;
						position:relative;
						z-index:6;
						background-color: rgba(255,255,255,0.65);
	
						ul#vid-control-btn{
							
							display:inline-block;
							position:absolute;
		
							top:50%;
							left:50%;
							background-color: rgba(255,255,255,0.45);
							border: 1px solid @c_3rd;
							border-radius: @r_borderRadius;
							margin:0;
							padding:0;
							list-style:none;
		
							.transform2d(@translateX: -50%, @translateY: -50%);
							
							.displayFlex(row);
							.flexWrap(nowrap);
							.justifyContent(center);
							.alignContent(center);
							.alignItems(center);
							
							li{
								margin:0;
								padding:@p_main;
								width:110px;
								height:110px;
								
								div{
									padding: ( @p_main / 2 );
									width:90px;
									height:90px;
									
									i{
										
										display:inline-block;
										width:100%;
										height:100%;
										
										&:before{
											content:" ";
											display:block;
											width:100%;
											height:100%;
											margin:0;
											padding:0;
											background-color:@c_3rd;
										}
									}
									&.btn-close-player{
										i{
											&:before{
												mask-image: url("/assets/img/svg/times-circle-regular.svg");
												-webkit-mask-image: url("/assets/img/svg/times-circle-regular.svg");
											}
										}
									}
									&.play-video{
										i{
											&:before{
												mask-image: url("/assets/img/svg/pause-circle-regular.svg");
												-webkit-mask-image: url("/assets/img/svg/pause-circle-regular.svg");
											}
										}
									}
									&.pause-video{
										i{
											&:before{
												mask-image: url("/assets/img/svg/play-circle-regular.svg");
												-webkit-mask-image: url("/assets/img/svg/play-circle-regular.svg");
											}
										}
									}
									&.btn-muted-player{
										i{
											width:70px;
											height:70px;
											border:10px solid @c_3rd;
											border-radius:50%;
											
											&:before{
												margin:0 auto;
												width:35px;
												height:70px;
												background-repeat:no-repeat;
												overflow:hidden;
												mask-image: url("/assets/img/svg/volume-off-solid.svg");
												-webkit-mask-image: url("/assets/img/svg/volume-off-solid.svg");
											}
										}
										&.sound-on{
											i{
												&:after{
													content:" ";
													width:10px;
													height:110px;
													display:block;
													background-color:@c_3rd;
													margin:-90px 0 0 30px;
				
													.transform2d(@rotate: -45deg);
												}
											}
										}
										&.sound-off{
											i{
												&:after{
													content:" ";
													width:10px;
													height:110px;
													display:none;
													background-color:@c_3rd;
													margin:-90px 0 0 30px;
				
													.transform2d(@rotate: -45deg);
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.block-load-box{
					display:inline-block;
					position:absolute;
					//margin-top: 0 ;
					top:0;
					left:0;
				
					video{
						display:block;
						width:100%;
						height:auto;
						position:relative;
						border: 1px solid transparent;
					}
					
					input[type=range]{
	
						-webkit-appearance: none;
						display:block;
						position:absolute;
						bottom:-75px;
						left:0;
						z-index:101;
						width:100%;
						height:75px;
	
						&::-webkit-slider-runnable-track {
							.track();
							//.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
							background: @c_6th;
							border-radius: @track-radius;
							border: @track-border-width solid @c_5th;
						}
						&::-webkit-slider-thumb {
							.thumb();
							-webkit-appearance: none;
							margin-top: ((-@track-border-width * 2 + @track-height) / 2) - (@thumb-height / 2);
						}
						&:focus::-webkit-slider-runnable-track {
							background: lighten(@c_6th, @contrast);
						}
						&::-moz-range-track {
							.track();
							//.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
							background: @c_6th;
							border-radius: @track-radius;
							border: 10px solid @c_5th;
						}
						&::-moz-range-thumb {
							.thumb();
						}
						&::-ms-track {
							.track();
							background: transparent;
							border-color: transparent;
							border-width: @thumb-width 0;
							color: transparent;
						}
						&::-ms-fill-lower {
							background: darken(@track-color, @contrast);
							border: @track-border-width solid @c_5th;
							border-radius: @track-radius*2;
							//.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
						}
						&::-ms-fill-upper {
							background: @track-color;
							border: @track-border-width solid @c_5th;
							border-radius: @track-radius*2;
							//.shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
						}
						&::-ms-thumb {
							.thumb();
						}
						&:focus::-ms-fill-lower {
							background: @track-color;
						}
						&:focus::-ms-fill-upper {
							background: lighten(@track-color, @contrast);
						}
					}
				}
			}
		}
	
		.block-provideo{
	
			display:block;
			width:100%;
			height:100%;
	
			#vid-controls{
				display:block;
			}
	
			.block-load-box{
				display:block;
				width:100%;
				height:100%;
			}
		}
	}
}



